<!--  -->
<template>
  <div class="download_index">
    <div class="download">
      <img class="download_img" src="../assets/img/4488@2x.png" alt="" />
    </div>
    <!-- 经销商 -->
    <div class="download_form">
      <el-form
        :rules="formRules"
        ref="formData"
        label-position="right"
        :model="formData"
      >
        <el-form-item prop="dealer">
          <div class="choose_dealers">
            <span style="color: #f56c6c; vertical-align: super; font-size: 15px"
              >*</span
            >选择经销商
          </div>
          <el-select
            style="width: 100%"
            clearable
            v-model="formData.dealer"
            value-key="id"
            placeholder=""
            filterable
            :loading="loading"
            @visible-change="hhandlechange"
          >
            <el-option
              v-for="item in nearbyArr"
              :key="item.id"
              :label="item.dealerName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 车型 -->
        <el-form-item prop="model">
          <div class="choose_dealers">选择车型</div>
          <el-select
            style="width: 100%"
            clearable
            v-model="formData.car"
            value-key="id"
            placeholder=""
          >
            <el-option
              v-for="item in modelArr"
              :key="item.id"
              :label="item.modelName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <div @click="handleclick('formData')" class="download_butt">
          生成小程序码
        </div>
      </el-form>
    </div>
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      top="20vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="infinite">{{ formData.dealer.dealerName }}</div>
      <div class="car">{{ formData.car.modelName }}</div>
      <div class="scan_code">
        <div class="scan_code1">
          <!-- <img :src="imgSrc" alt="" /> -->
          <el-image
            v-loading="imgloading"
            style="width: 100%; height: 100%"
            :src="imgSrc"
            :fit="fit"
          >
            <div
              slot="error"
              class="image-slot"
              style="width: 100%; height: 100%"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="scan_code2">请使用微信扫码</div>
      </div>
      <div class="Download_code" @click="downloadQRCode">下载二维码</div>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import queryString from "querystring";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      fit: "contain",
      imgSrc: "",
      loading: false,
      imgloading: false,
      dialogVisible: false,
      rolesLoading: false,
      formData: {
        dealer: {},
        car: {},
      },
      formRules: {
        dealer: [{ required: true, message: "请选择经销商", trigger: "blur" }],
      },
      nearbyArr: [], //经销商
      modelArr: [], //车型
      // baseUrl: "192.168.1.195:49998",//测试
      // baseUrl: "ddns.lemlink.com:49998", //测式
      baseUrl: "https://wxapp-api.dongfenginfiniti.com.cn", //正式
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    // dialogVisible: function (newVal, oldVal) {
    //   if (!newVal) {
    //     this.imgloading = true;
    //   }
    // },
  },
  // 方法集合
  methods: {
    downloadQRCode() {
      // 下载二维码
      let link = document.createElement("a");
      let url = this.imgSrc; //codeIMG  要下载的路径
      // 这里是将url转成blob地址，
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          //  console.log(link.href)
          if (this.formData.car.modelName) {
            link.download =
              this.formData.dealer.dealerName +
              "-" +
              this.formData.car.modelName;
          } else {
            link.download = this.formData.dealer.dealerName;
          }
          document.body.appendChild(link);
          link.click();
        });
    },
    // 下拉框隐藏重置经销商列表
    hhandlechange(val) {
      // console.log(val);
      if (!val) {
        this.getnearbyList();
      }
    },
    //服务端搜索
    // remoteMethod(query) {
    //   // console.log(query);
    //   if (query !== "") {
    //     this.loading = true;
    //     setTimeout(() => {
    //       this.loading = false;
    //       this.$http
    //         .post(
    //           `${this.baseUrl}/api/dealer/dealer/nearby`,
    //           queryString.stringify({ dealerName: query })
    //         )
    //         .then((res) => {
    //           this.nearbyArr = res.data.data;
    //           // console.log(res.data.data);
    //         });
    //     }, 200);
    //   } else {
    //     this.nearbyArr = [];
    //   }
    // },
    handleclick(formName) {
      console.log(this.formData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var postData = this.adminApi.copyObj({
            dealerName: this.formData.dealer.dealerCode,
            model: this.formData.car ? this.formData.car.modelCode : "",
          });
          // this.imgloading = true;
          this.getwxapp(postData);
        } else {
          this.$message("请检查输入信息");
          return false;
        }
      });
    },
    // 生成小程序码
    getwxapp(postData) {
      this.imgloading = true;
      if (postData.model == "") {
        this.imgSrc =
          `${this.baseUrl}/api/wxapp/getUnlimitedQRCode?scene=` +
          postData.dealerName +
          "AND";
      } else {
        this.imgSrc =
          `${this.baseUrl}/api/wxapp/getUnlimitedQRCode?scene=` +
          postData.dealerName +
          "AND" +
          postData.model;
      }
      setTimeout(() => {
        this.imgloading = false;
      }, 200);
      this.dialogVisible = true;
      // this.$http
      //   .get("http://192.168.1.195:49998/api/wxapp/getUnlimitedQRCode?scene=" + postData.dealerName + 'AND' + postData.model)
      //   .then((res) => {
      //     console.log(res);
      //     console.log(res.headers);
      //   });
    },
    //经销商列表
    getnearbyList() {
      this.$http.post(`${this.baseUrl}/api/dealer/dealer/list`).then((res) => {
        this.nearbyArr = res.data.data;
      });
    },
    //车型列表
    getmodelList() {
      this.$http.post(`${this.baseUrl}/api/car/model/list`).then((res) => {
        this.modelArr = res.data.data;
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getnearbyList();
    this.getmodelList();
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.download_index {
  .download {
    text-align: center;
    border-bottom: 1px solid #e5e5e4;
    padding: 4vh 0;
    margin: 0 12.5vw;
    .download_img {
      width: 144px;
      height: 66px;
    }
  }
  .download_form {
    padding-top: 24vh;
  }
  .download_butt {
    width: 400px;
    height: 50px;
    background: #000;
    // font-family: "PingFang SC S0pxibold";
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #fff;
    line-height: 50px;
    margin: 0 auto;
    margin-top: 42px;
    cursor: pointer;
  }
  .choose_dealers {
    // font-family: "PingFang SC S0pxibold";
    font-weight: 600;
    font-size: 22px;
    color: #000;
    padding-bottom: 7px;
  }
  ::v-deep.el-input__inner {
    height: 50px;
    border: 2px solid #000;
    opacity: 0.8;
  }
  ::v-deep.el-select .el-input .el-select__caret {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
  }
  .infinite {
    // font-family: "PingFang SC S0pxibold";
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #000;
  }
  .car {
    // font-family: "PingFang SC S0pxibold";
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #000;
    padding-top: 5px;
    padding-bottom: 20px;
  }
  .scan_code {
    width: 202px;
    background: #ebebeb;
    margin: 0 auto;
    // padding: 7px 7px;
    padding: 14px 7px 14px 7px;
  }
  .scan_code1 {
    width: 100%;
    height: 198px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .scan_code2 {
    // font-family: "HYQiHei 50S";
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #999;
    margin-top: 7px;
  }
  .Download_code {
    width: 293px;
    height: 50px;
    background: #000;
    // font-family: "PingFang SC S0pxibold";
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #fff;
    line-height: 50px;
    margin: 0 auto;
    margin-top: 42px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  ::v-deep.el-select-dropdown__item.selected {
    color: #000000;
  }
  @media screen and (max-height: 600px) {
    .download_form {
      padding-top: 5vh;
    }
  }
  @media screen and (max-width: 414px) {
    ::v-deep.el-form--label-right {
      width: 300px;
      margin: 0 auto;
    }
    .download_butt {
      width: 300px;
      height: 50px;
      background: #000;
      // font-family: "PingFang SC S0pxibold";
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: #fff;
      line-height: 50px;
      margin: 0 auto;
      margin-top: 42px;
      cursor: pointer;
    }
    ::v-deep.el-dialog {
      width: 340px;
    }
  }
}
</style>
<style lang="scss">
.el-select-dropdown__item.selected {
  color: #000000;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #000000;
}
.el-form--label-right {
  width: 400px;
  margin: 0 auto;
}
.image-slot {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-dialog {
  width: 400px;
}
</style>